import React from 'react'

import modalStyles from './modal.module.scss'

const Modal = props => (
    <div className={modalStyles.modal} onClick={props.hideModal}>
        <section className={modalStyles.modalContent}>
            {props.children}
            <section className={modalStyles.modalClose}>
                <button onClick={props.hideModal}>X</button>
            </section>
        </section>
    </div>
)

export default Modal;