import React, { useState } from "react"
import { graphql, useStaticQuery } from 'gatsby'

import Layout from '../components/layout'
import indexStyles from '../pages/index.module.scss'
import Head from '../components/head'
import Modal from '../components/modal/modal'

const IndexPage = () => {
  
  let [isOpen, setIsOpen] = useState(false);
  let [imageUrl, setImageUrl] = useState(null);

  const data = useStaticQuery(graphql`
    query {
      allContentfulPortfolioItem (
        sort: {
          fields: listOrderNumber
        }
      ) {
        edges {
          node {
            clientName
            listOrderNumber
            websiteBuild
            oldTheme
            gameBuild
            appBuild
            buildDetails
            websiteUrl
            websiteImageThumb {
              title
              file {
                url
              }
            }
            websiteScreenshot {
              title
              file {
                url
              }
            }
          }
        }
      }
    }
  `)

  function showModal(url) {
    setImageUrl(url);
    setIsOpen(true);
  }

  function hideModal() {
    setIsOpen(false);
  }


  return (
    <Layout>
      { isOpen ? <Modal hideModal={hideModal}><img src={imageUrl} alt="website homepage" /></Modal> : null }
      <Head title="Home" />
      <div className={indexStyles.innerwrap }>
        <div className={indexStyles.portfolioList }>
          { data.allContentfulPortfolioItem.edges.map((edge) => {
            return (

                <div className={indexStyles.tile } key={ edge.node.clientName } style={{backgroundImage: `url(${edge.node.websiteImageThumb.file.url})`}}>
                  { edge.node.gameBuild === true ? <div className={indexStyles.gameBuild }>Game</div> : null }
                  { edge.node.appBuild === true ? <div className={indexStyles.appBuild }>Web<br />App</div> : null }
                  <div className={indexStyles.overlay }></div>
                  <div className={indexStyles.tileInfo }>
                    { edge.node.websiteUrl ? <div className={indexStyles.tileLive}>Live</div> : <div className={indexStyles.tileOffline}>Offline</div>}
                    { edge.node.oldTheme === true ? <div className={indexStyles.oldTheme}>Previous Theme</div> : null }
                    <h4>{ edge.node.clientName }</h4>
                    <p>
                      { edge.node.buildDetails.map((detail) => {
                        return (
                          <span className={indexStyles.tileDetail } key={detail}>{detail}<span className={indexStyles.comma }>,</span></span>
                        )
                      })}
                    </p>
                    { edge.node.websiteUrl ? <a href={edge.node.websiteUrl} target="_blank" rel="noreferrer nofollow"><button>Visit Site</button></a> : <button onClick={() => showModal(edge.node.websiteScreenshot.file.url)}>View Screenshot</button> }
                  </div>
                </div>
              
            )
          })}
        </div>
      </div>
    </Layout>
  )

}


export default IndexPage